import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Advanced from './Advanced';

import BiriyaniRestaurant from './BiriyaniRestaurant';
import KulambuKadai from './KulambuKadai';

import Franchise from './Franchise';

export default function App() {
  return (
    <Switch>
      <Route path='/' component={Advanced} exact />
      <Route path='/roi-calculator' component={Advanced} exact />

      <Route path='/kulambu-kadai' component={KulambuKadai} exact />
      <Route
        path='/roi-calculator/kulambu-kadai'
        component={KulambuKadai}
        exact
      />

      <Route path='/biriyani-restaurant' component={BiriyaniRestaurant} exact />
      <Route
        path='/roi-calculator/biriyani-restaurant'
        component={BiriyaniRestaurant}
        exact
      />

      <Route path='/franchise' component={Franchise} exact />
      <Route path='/roi-calculator/franchise' component={Franchise} exact />
    </Switch>
  );
}
