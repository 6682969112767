import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import request from 'request';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import {
  DEFAULT_AVERAGE_RECIPE_COOKING_TIME,
  DEFAULT_KITCHEN_OPERATING_HOURS_PER_DAY,
  DEFAULT_ELECTRICITY_UNIT_COST_PER_HOUR,
  DEFAULT_DAYS_IN_MONTH,
  DEFAULT_NO_OF_EMPLOYEES,
  DEFAULT_AVERAGE_EMPLOYEE_SALARY,
  DEFAULT_ADVANCE_DEPOSIT,
  DEFAULT_MONTHLY_RENT,
  DEFAULT_MISC_COSTS,
  DEFAULT_FRANCHISE_OPTION,
} from './data/constants';

import { franchiseOptions } from './data/franchiseOptions';

// import html2canvas from 'html2canvas'

const moment = require('moment');
require('moment-precise-range-plugin');

const places = require('places.js');

const Bearer = require('@bearer/node')('FUFbE-aEBIbyWN5aVuX3wpWVp5pMOL8C');
const gsheet = Bearer.integration('google_sheets');

const spreadsheetId = '1IfBsQbr_gcANt48qUVLLNXK8sMZnDo_O3sxuYzMduac';

const inrToUsd = 75;

function toCurrencyString(amount: number, currency: string) {
  let locale = 'en-IN';
  if (currency === 'USD') {
    locale = 'en-US';
  }
  if (!amount) {
    amount = 0;
  }
  return amount.toLocaleString(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: 'currency',
    currency: currency,
  });
}

function getHumanReadableStringFromDays(days: number) {
  return moment.preciseDiff(
    new Date().setDate(new Date().getDate() + days),
    new Date()
  );
}

let placesAutocomplete: any;

let uniqueKey = 0;

function Franchise() {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();

  let tmpCurrency = query.get('currency');
  if (!tmpCurrency) {
    tmpCurrency = 'INR';
  }
  const [currency, setCurrency] = useState(tmpCurrency);

  const [mealsPerDay, setMealsPerDay] = useState<number>(
    getParam('mealsPerDay', 200)
  );
  const [mealsPerDayError, setMealsPerDayError] = useState('');
  const [mealSellingPrice, setMealSellingPrice] = useState<number>(
    getParam('mealSellingPrice', 200)
  );
  const [mealSellingPriceError, setMealSellingPriceError] = useState('');
  const [mealIngredientsCost, setMealIngredientsCost] = useState<number>(
    getParam('mealIngredientsCost', 100)
  );
  const [mealIngredientsCostError, setMealIngredientsCostError] = useState('');

  const [bulkCookingQuantity, setBulkCookingQuantity] = useState<number>(
    getParam('bulkCookingQuantity', franchiseOptions.C.maximumServings)
  );
  const [bulkCookingQuantityError, setBulkCookingQuantityError] = useState('');
  const [averageRecipeCookingTime, setAverageRecipeCookingTime] = useState<
    number
  >(getParam('averageRecipeCookingTime', DEFAULT_AVERAGE_RECIPE_COOKING_TIME));
  const [
    averageRecipeCookingTimeError,
    setAverageRecipeCookingTimeError,
  ] = useState('');
  const [
    kitchenOperatingHoursPerDay,
    setKitchenOperatingHoursPerDay,
  ] = useState<number>(
    getParam(
      'kitchenOperatingHoursPerDay',
      DEFAULT_KITCHEN_OPERATING_HOURS_PER_DAY
    )
  );
  const [
    kitchenOperatingHoursPerDayError,
    setKitchenOperatingHoursPerDayError,
  ] = useState('');

  const [franchiseOption, setFranchiseOption] = useState<string>(
    query.get('robochefModel') || DEFAULT_FRANCHISE_OPTION
  );

  const [electricityUnitCostPerHour, setElectricityUnitCostPerHour] = useState<
    number
  >(
    getParam(
      'electricityUnitCostPerHour',
      DEFAULT_ELECTRICITY_UNIT_COST_PER_HOUR
    )
  );
  const [
    electricityUnitCostPerHourError,
    setElectricityUnitCostPerHourError,
  ] = useState('');

  const [
    electricityUnitsConsumedPerDay,
    setElectricityUnitsConsumedPerDay,
  ] = useState(0);
  const [electricityCostPerDay, setElectricityCostPerDay] = useState(0);

  const [noOfEmployees, setNoOfEmployees] = useState<number>(
    getParam('noOfEmployees', DEFAULT_NO_OF_EMPLOYEES)
  );
  const [noOfEmployeesError, setNoOfEmployeesError] = useState('');

  const [averageEmployeeSalary, setAverageEmployeeSalary] = useState<number>(
    getParam('averageEmployeeSalary', DEFAULT_AVERAGE_EMPLOYEE_SALARY)
  );
  const [averageEmployeeSalaryError, setAverageEmployeeError] = useState('');

  const [advanceDeposit, setAdvanceDeposit] = useState<number>(
    getParam('advanceDeposit', DEFAULT_ADVANCE_DEPOSIT)
  );
  const [advanceDepositError, setAdvanceDepositError] = useState('');

  const [monthlyRent, setMonthlyRent] = useState<number>(
    getParam('monthlyRent', DEFAULT_MONTHLY_RENT)
  );
  const [monthlyRentError, setMonthlyRentError] = useState('');

  const [misc1] = useState(query.get('misc1') || 'Monthly Misc Costs 1');
  const [miscCosts1, setMisc1Costs] = useState<number>(
    getParam('miscCosts1', DEFAULT_MISC_COSTS)
  );
  const [miscCosts1Error, setMiscCosts1Error] = useState('');

  const [misc2] = useState(query.get('misc2') || 'Monthly Misc Costs 2');
  const [miscCosts2, setMisc2Costs] = useState<number>(
    getParam('miscCosts2', DEFAULT_MISC_COSTS)
  );
  const [miscCosts2Error, setMiscCosts2Error] = useState('');

  const [misc3] = useState(query.get('misc3') || 'Monthly Misc Costs 3');
  const [miscCosts3, setMisc3Costs] = useState<number>(
    getParam('miscCosts3', DEFAULT_MISC_COSTS)
  );
  const [miscCosts3Error, setMiscCosts3Error] = useState('');

  const [misc4] = useState(query.get('misc4') || 'Monthly Misc Costs 4');
  const [miscCosts4, setMisc4Costs] = useState<number>(
    getParam('miscCosts4', DEFAULT_MISC_COSTS)
  );
  const [miscCosts4Error, setMiscCosts4Error] = useState('');

  const [franchiseCost, setFranchiseCost] = useState<number>(
    getParam('franchiseCost', franchiseOptions[DEFAULT_FRANCHISE_OPTION].price)
  );
  const [unitsPerHour, setUnitsPerHour] = useState<number>(
    getParam(
      'unitsPerHour',
      franchiseOptions[DEFAULT_FRANCHISE_OPTION].unitsPerHour
    )
  );

  const [laborCostsPerMonth, setLaborCostsPerMonth] = useState(0);
  const [miscCostsPerMonth, setMiscCostsPerMonth] = useState(0);
  const [ingredientsCostPerMonth, setIngredientsCostPerMonth] = useState(0);
  const [revenuePerMonth, setRevenuePerMonth] = useState(0);
  const [profitPerMonth, setProfitPerMonth] = useState(0);

  const [investment, setInvestment] = useState(0);
  const [bepString, setBepString] = useState('-');
  const [annualRoi, setAnnualRoi] = useState(0);

  const [changeReasonText, setChangeReasonText] = useState('');
  const [displayChangeReason, setDisplayChangeReason] = useState('none');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const [displayContactFormLoading, setDisplayContactFormLoading] = useState(
    'none'
  );
  const [displayContactFormSuccess, setDisplayContactFormSuccess] = useState(
    'none'
  );
  const [displayContactFormFailure, setDisplayContactFormFailure] = useState(
    'none'
  );

  function getParam(param: string, defaultValue: number) {
    let value;
    const valueString = query.get(param);
    if (valueString) {
      value = parseFloat(valueString);
      if (value === -1) {
        value = defaultValue;
      }
    } else {
      value = defaultValue;
    }
    return value;
  }

  function getUrlQueryString() {
    const params = new URLSearchParams({
      currency: currency,
      mealsPerDay: mealsPerDay.toString(),
      mealSellingPrice: mealSellingPrice.toString(),
      mealIngredientsCost: mealIngredientsCost.toString(),
      bulkCookingQuantity: bulkCookingQuantity.toString(),
      averageRecipeCookingTime: averageRecipeCookingTime.toString(),
      kitchenOperatingHoursPerDay: kitchenOperatingHoursPerDay.toString(),
      franchiseOption: franchiseOption,
      electricityUnitCostPerHour: electricityUnitCostPerHour.toString(),
      noOfEmployees: noOfEmployees.toString(),
      averageEmployeeSalary: averageEmployeeSalary.toString(),
      advanceDeposit: advanceDeposit.toString(),
      monthlyRent: monthlyRent.toString(),
      misc1: misc1,
      miscCosts1: miscCosts1.toString(),
      misc2: misc2,
      miscCosts2: miscCosts2.toString(),
      misc3: misc3,
      miscCosts3: miscCosts3.toString(),
      misc4: misc4,
      miscCosts4: miscCosts4.toString(),
      franchiseCost: franchiseCost.toString(),
      unitsPerHour: unitsPerHour.toString(),
    });
    return params.toString();
  }

  useEffect(() => {
    function getUrlQueryString() {
      const params = new URLSearchParams({
        currency: currency,
        mealsPerDay: mealsPerDay.toString(),
        mealSellingPrice: mealSellingPrice.toString(),
        mealIngredientsCost: mealIngredientsCost.toString(),
        bulkCookingQuantity: bulkCookingQuantity.toString(),
        averageRecipeCookingTime: averageRecipeCookingTime.toString(),
        kitchenOperatingHoursPerDay: kitchenOperatingHoursPerDay.toString(),
        franchiseOption: franchiseOption,
        electricityUnitCostPerHour: electricityUnitCostPerHour.toString(),
        noOfEmployees: noOfEmployees.toString(),
        averageEmployeeSalary: averageEmployeeSalary.toString(),
        advanceDeposit: advanceDeposit.toString(),
        monthlyRent: monthlyRent.toString(),
        misc1: misc1,
        miscCosts1: miscCosts1.toString(),
        misc2: misc2,
        miscCosts2: miscCosts2.toString(),
        misc3: misc3,
        miscCosts3: miscCosts3.toString(),
        misc4: misc4,
        miscCosts4: miscCosts4.toString(),
        franchiseCost: franchiseCost.toString(),
        unitsPerHour: unitsPerHour.toString(),
      });
      return params.toString();
    }

    history.push(window.location.pathname + '?' + getUrlQueryString());
  }, [
    averageEmployeeSalary,
    averageRecipeCookingTime,
    bulkCookingQuantity,
    currency,
    electricityUnitCostPerHour,
    history,
    kitchenOperatingHoursPerDay,
    mealIngredientsCost,
    mealSellingPrice,
    mealsPerDay,
    noOfEmployees,
    franchiseOption,
    advanceDeposit,
    monthlyRent,
    misc1,
    miscCosts1,
    misc2,
    miscCosts2,
    misc3,
    miscCosts3,
    misc4,
    miscCosts4,
    franchiseCost,
    unitsPerHour,
  ]);

  function getSheetsData() {
    return [
      [
        new Date(),
        `/?${getUrlQueryString()}`,
        currency,
        mealsPerDay,
        mealSellingPrice,
        mealIngredientsCost,
        bulkCookingQuantity,
        averageRecipeCookingTime,
        kitchenOperatingHoursPerDay,
        franchiseOption,
        electricityUnitCostPerHour,
        electricityUnitsConsumedPerDay,
        electricityCostPerDay,
        noOfEmployees,
        averageEmployeeSalary,
        advanceDeposit,
        monthlyRent,
        misc1,
        miscCosts1,
        misc2,
        miscCosts2,
        misc3,
        miscCosts3,
        misc4,
        miscCosts4,
        franchiseCost,
        unitsPerHour,
        profitPerMonth,
        investment,
        bepString,
        annualRoi,
        name,
        email,
        phone,
        placesAutocomplete.getVal(),
        message,
      ],
    ];
  }

  function appendToSheets() {
    const data = getSheetsData();
    gsheet
      .auth('23a03860-7c1d-11ea-acf0-6992bb43099f')
      .post(`${spreadsheetId}/values/A1:append`, {
        body: { values: data },
        query: { valueInputOption: 'RAW' },
      })
      .then(() => {
        console.log('Saved!');
      });
  }

  useEffect(() => {
    placesAutocomplete = places({
      appId: process.env.REACT_APP_ALGOLIA_APP_ID,
      apiKey: process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY,
      container: document.querySelector('#country'),
      templates: {
        suggestion: function (suggestion: any) {
          return (
            '<i class="flag ' +
            suggestion.countryCode +
            '"></i> ' +
            suggestion.highlight.name
          );
        },
      },
    }).configure({
      type: 'country',
    });
  }, []);

  useEffect(() => {
    function showChangeReason(text: string) {
      console.log(text);
      setChangeReasonText(text);
      if (displayChangeReason !== 'block') {
        setDisplayChangeReason('block');
      }
    }

    function calculateRoi() {
      let fOption = franchiseOptions[franchiseOption];
      if (!fOption) {
        setFranchiseOption(DEFAULT_FRANCHISE_OPTION);
        return;
      }

      // Kitchen Operating Hours per day
      if (kitchenOperatingHoursPerDay && kitchenOperatingHoursPerDay <= 0) {
        setKitchenOperatingHoursPerDayError(
          'Kitchen Operating Hours per day must be atleast 1 hour'
        );
      }
      if (kitchenOperatingHoursPerDay && kitchenOperatingHoursPerDay > 24) {
        setKitchenOperatingHoursPerDayError(
          'Kitchen Operating Hours per day cannot be more than 24 hours'
        );
      }
      if (
        kitchenOperatingHoursPerDay &&
        kitchenOperatingHoursPerDay >= 1 &&
        kitchenOperatingHoursPerDay <= 24
      ) {
        setKitchenOperatingHoursPerDayError('');
      }

      // Average Recipe Cooking Time in minutes
      if (averageRecipeCookingTime && averageRecipeCookingTime <= 0) {
        setAverageRecipeCookingTimeError(
          'Average Recipe Cooking Time must be atleast 1 minute'
        );
      }
      if (averageRecipeCookingTime && averageRecipeCookingTime >= 1) {
        setAverageRecipeCookingTimeError('');
      }
      if (
        averageRecipeCookingTime &&
        kitchenOperatingHoursPerDay &&
        averageRecipeCookingTime > kitchenOperatingHoursPerDay * 60
      ) {
        setAverageRecipeCookingTimeError(
          `Average Recipe Cooking Time in minutes cannot be more than Kitchen Operating Minutes per day which is ${
            kitchenOperatingHoursPerDay * 60
          }`
        );
      }
      if (
        averageRecipeCookingTime &&
        kitchenOperatingHoursPerDay &&
        averageRecipeCookingTime <= kitchenOperatingHoursPerDay * 60
      ) {
        setAverageRecipeCookingTimeError('');
      }

      // No. of Meals per day
      if (mealsPerDay && mealsPerDay <= 0) {
        setMealsPerDayError('No. of meals per day must be atleast 1 meal');
      }
      if (mealsPerDay && mealsPerDay >= 1) {
        setMealsPerDayError('');
      }

      // Selling Price of a meal
      if (mealSellingPrice && mealSellingPrice < 0) {
        setMealSellingPriceError(
          'Selling price of a meal cannot be less than 0'
        );
      }
      if (mealSellingPrice && mealSellingPrice >= 0) {
        setMealSellingPriceError('');
      }

      // Ingredients Cost per meal
      if (mealIngredientsCost && mealIngredientsCost < 0) {
        setMealIngredientsCostError('Ingredients Cost cannot be less than 0');
      }
      if (mealIngredientsCost && mealIngredientsCost >= 0) {
        setMealIngredientsCostError('');
      }

      if (!bulkCookingQuantity || bulkCookingQuantity < 0) {
        return;
      }

      // Decrease Franchise Option due to minimum servings
      if (bulkCookingQuantity < fOption.minimumServings) {
        for (let i = Object.keys(franchiseOptions).length - 1; i >= 0; i--) {
          const key = Object.keys(franchiseOptions)[i];
          const m = franchiseOptions[key];
          if (m.minimumServings <= bulkCookingQuantity) {
            showChangeReason(
              `Changed Franchise Option from ${franchiseOption} to ${key} since ${franchiseOption} has a minimum cooking limit of ${fOption.minimumServings} per vessel`
            );
            setFranchiseOption(key);
            fOption = m;
            break;
          }
        }
      }

      if (
        !kitchenOperatingHoursPerDay ||
        !averageRecipeCookingTime ||
        !mealsPerDay
      ) {
        return;
      }

      let cookingQuantity = fOption.maximumServings;
      if (bulkCookingQuantity < cookingQuantity) {
        cookingQuantity = bulkCookingQuantity;
        setBulkCookingQuantityError(
          `Bulk Cooking Quantity can be increased to ${fOption.maximumServings} which is ${franchiseOption}'s maximum servings in a vessel`
        );
      }

      let currencyConversionFactor = 1;
      if (currency === 'USD') {
        currencyConversionFactor = 1 / inrToUsd;
      }

      if (!mealSellingPrice) {
        return;
      }
      if (!mealIngredientsCost) {
        return;
      }

      const revenuePerDay = mealsPerDay * mealSellingPrice;
      const totalIngredientsCost = mealsPerDay * mealIngredientsCost;
      const totalLaborCostPerDay =
        (noOfEmployees * averageEmployeeSalary) / DEFAULT_DAYS_IN_MONTH;
      const rentPerDay = monthlyRent / DEFAULT_DAYS_IN_MONTH;
      const miscCostsPerDay =
        miscCosts1 / DEFAULT_DAYS_IN_MONTH +
        miscCosts2 / DEFAULT_DAYS_IN_MONTH +
        miscCosts3 / DEFAULT_DAYS_IN_MONTH +
        miscCosts4 / DEFAULT_DAYS_IN_MONTH;

      const profitPerDay =
        revenuePerDay -
        (totalIngredientsCost +
          electricityCostPerDay +
          totalLaborCostPerDay +
          rentPerDay +
          miscCostsPerDay);

      setLaborCostsPerMonth(totalLaborCostPerDay * DEFAULT_DAYS_IN_MONTH);
      setMiscCostsPerMonth(miscCosts1 + miscCosts2 + miscCosts3 + miscCosts4);
      setIngredientsCostPerMonth(totalIngredientsCost * DEFAULT_DAYS_IN_MONTH);
      setRevenuePerMonth(revenuePerDay * DEFAULT_DAYS_IN_MONTH);
      setProfitPerMonth(profitPerDay * DEFAULT_DAYS_IN_MONTH);

      setInvestment(franchiseCost * currencyConversionFactor + advanceDeposit);
      const bepInDays = Math.ceil(investment / profitPerDay);
      if (bepInDays < 0) {
        setBepString('-');
      } else {
        setBepString(getHumanReadableStringFromDays(bepInDays));
      }
      const profitPerYear = profitPerDay * 365;
      setAnnualRoi(Math.round((profitPerYear / investment) * 100));
    }
    calculateRoi();
  }, [
    averageRecipeCookingTime,
    bulkCookingQuantity,
    currency,
    displayChangeReason,
    electricityCostPerDay,
    investment,
    kitchenOperatingHoursPerDay,
    mealIngredientsCost,
    mealSellingPrice,
    mealsPerDay,
    franchiseOption,
    noOfEmployees,
    averageEmployeeSalary,
    advanceDeposit,
    monthlyRent,
    miscCosts1,
    miscCosts2,
    miscCosts3,
    miscCosts4,
    franchiseCost,
  ]);

  useEffect(() => {
    if (
      bulkCookingQuantity &&
      bulkCookingQuantity <
        franchiseOptions[Object.keys(franchiseOptions)[0]].minimumServings
    ) {
      setBulkCookingQuantityError(
        `Bulk Cooking Quantity must be atleast ${
          franchiseOptions[Object.keys(franchiseOptions)[0]].minimumServings
        } serving`
      );
    }
    if (
      bulkCookingQuantity &&
      bulkCookingQuantity >=
        franchiseOptions[Object.keys(franchiseOptions)[0]].minimumServings
    ) {
      setBulkCookingQuantityError('');
    }
  }, [bulkCookingQuantity, franchiseOption]);

  useEffect(() => {
    if (electricityUnitCostPerHour && electricityUnitCostPerHour < 0) {
      setElectricityUnitCostPerHourError(
        'Electricity Unit Cost per hour cannot be less than 0'
      );
    }
    if (electricityUnitCostPerHour && electricityUnitCostPerHour >= 0) {
      setElectricityUnitCostPerHourError('');
    }
  }, [electricityUnitCostPerHour]);

  useEffect(() => {
    if (!electricityUnitCostPerHour || !kitchenOperatingHoursPerDay) {
      return;
    }

    setElectricityUnitsConsumedPerDay(
      unitsPerHour * kitchenOperatingHoursPerDay
    );
  }, [electricityUnitCostPerHour, kitchenOperatingHoursPerDay, unitsPerHour]);

  useEffect(() => {
    if (!electricityUnitCostPerHour) {
      return;
    }
    setElectricityCostPerDay(
      electricityUnitsConsumedPerDay * electricityUnitCostPerHour
    );
  }, [electricityUnitCostPerHour, electricityUnitsConsumedPerDay]);

  useEffect(() => {
    if (noOfEmployees < 1) {
      setNoOfEmployeesError('Number of Employees cannot be less than 1');
    } else {
      setNoOfEmployeesError('');
    }
  }, [noOfEmployees]);

  useEffect(() => {
    if (averageEmployeeSalary < 0) {
      setAverageEmployeeError('Average Employee Salary cannot be less than 0');
    } else {
      setAverageEmployeeError('');
    }
  }, [averageEmployeeSalary]);

  useEffect(() => {
    if (advanceDeposit < 0) {
      setAdvanceDepositError('Advance Deposit cannot be less than 0');
    } else {
      setAdvanceDepositError('');
    }
  }, [advanceDeposit]);

  useEffect(() => {
    if (monthlyRent < 0) {
      setMonthlyRentError('Monthly Rent cannot be less than 0');
    } else {
      setMonthlyRentError('');
    }
  }, [monthlyRent]);

  useEffect(() => {
    if (miscCosts1 < 0) {
      setMiscCosts1Error(`${misc1} 1 cannot be less than 0`);
    } else {
      setMiscCosts1Error('');
    }
  }, [misc1, miscCosts1]);
  useEffect(() => {
    if (miscCosts2 < 0) {
      setMiscCosts2Error(`${misc2} cannot be less than 0`);
    } else {
      setMiscCosts2Error('');
    }
  }, [misc2, miscCosts2]);
  useEffect(() => {
    if (miscCosts3 < 0) {
      setMiscCosts3Error(`${misc3} cannot be less than 0`);
    } else {
      setMiscCosts3Error('');
    }
  }, [misc3, miscCosts3]);
  useEffect(() => {
    if (miscCosts4 < 0) {
      setMiscCosts4Error(`${misc4} cannot be less than 0`);
    } else {
      setMiscCosts4Error('');
    }
  }, [misc4, miscCosts4]);

  useEffect(() => {
    const fOption = franchiseOptions[franchiseOption];
    setFranchiseCost(fOption.price);
    setUnitsPerHour(fOption.unitsPerHour);
  }, [franchiseOption]);

  function getFormHtml() {
    const fields = [
      {
        title: 'Average Bulk Cooking Quantity per cycle in servings',
        value: bulkCookingQuantity,
        method: setBulkCookingQuantity,
        errorText: bulkCookingQuantityError,
        toolTipContent:
          'What is the average number of servings in one cooking cycle? Increasing this value decreases the number of machines needed.',
      },
      {
        title: 'Average Recipe Cooking Time in minutes',
        value: averageRecipeCookingTime,
        method: setAverageRecipeCookingTime,
        errorText: averageRecipeCookingTimeError,
        toolTipContent:
          'Increasing this value decreases the number of cooking cycles per day and therefore increases the number of machines needed to make the required meals per day',
      },
      {
        title: 'Kitchen Operating Hours per day',
        value: kitchenOperatingHoursPerDay,
        method: setKitchenOperatingHoursPerDay,
        errorText: kitchenOperatingHoursPerDayError,
        toolTipContent:
          'Number of cooking cycles per day = (Kitchen Operating Hours per day * 60) / Average Recipe Cooking Time in minutes',
      },
      {
        title: 'Electricity Unit Cost per hour',
        value: electricityUnitCostPerHour,
        method: setElectricityUnitCostPerHour,
        errorText: electricityUnitCostPerHourError,
        toolTipContent: 'Used to calculate Electricity Cost per month',
      },
      {
        title: 'No. of Meals per day',
        value: mealsPerDay,
        method: setMealsPerDay,
        errorText: mealsPerDayError,
        toolTipContent:
          'How many meals are sold in a day? Increasing this value increases the number of machines needed',
        className: 'inputdiv33',
      },
      {
        title: 'Selling Price of a meal',
        value: mealSellingPrice,
        method: setMealSellingPrice,
        errorText: mealSellingPriceError,
        toolTipContent:
          'Revenue per day = Selling Price of a meal * No. of Meals per day',
        className: 'inputdiv33',
      },
      {
        title: 'Ingredients Cost per meal',
        value: mealIngredientsCost,
        method: setMealIngredientsCost,
        errorText: mealIngredientsCostError,
        toolTipContent:
          'Profit per day = Revenue per day - (Ingredients Cost per meal * No. of Meals per day) - Electricity Cost per day',
        className: 'inputdiv33',
      },
      {
        title: 'Number of Employees',
        value: noOfEmployees,
        method: setNoOfEmployees,
        errorText: noOfEmployeesError,
        toolTipContent: 'Used to calculate Labor Costs',
      },
      {
        title: 'Average Employee Salary',
        value: averageEmployeeSalary,
        method: setAverageEmployeeSalary,
        errorText: averageEmployeeSalaryError,
        toolTipContent: 'Average Employee Salery per month',
      },
      {
        title: 'Advance Deposit',
        value: advanceDeposit,
        method: setAdvanceDeposit,
        errorText: advanceDepositError,
        toolTipContent: 'Advance Deposit',
      },
      {
        title: 'Monthly Rent',
        value: monthlyRent,
        method: setMonthlyRent,
        errorText: monthlyRentError,
        toolTipContent: 'Rent per Month',
      },
      {
        title: misc1,
        value: miscCosts1,
        method: setMisc1Costs,
        errorText: miscCosts1Error,
        toolTipContent: misc1,
      },
      {
        title: misc2,
        value: miscCosts2,
        method: setMisc2Costs,
        errorText: miscCosts2Error,
        toolTipContent: misc2,
      },
      {
        title: misc3,
        value: miscCosts3,
        method: setMisc3Costs,
        errorText: miscCosts3Error,
        toolTipContent: misc3,
      },
      {
        title: misc4,
        value: miscCosts4,
        method: setMisc4Costs,
        errorText: miscCosts4Error,
        toolTipContent: misc4,
      },
    ];

    const formHtml: any[] = [];
    formHtml.push(
      <div key={uniqueKey++} className='robochefmodelinputdiv'>
        <div
          className='twobuttonradiogroupdiv'
          onChange={(event: any) => {
            setCurrency(event.target.value);
          }}
        >
          <div style={{ marginLeft: '10px', marginRight: '10px' }}>
            <input
              type='radio'
              id='INR'
              name='currency'
              value='INR'
              defaultChecked={currency === 'INR'}
            />
            <label htmlFor='INR' className='w-form-label'>
              INR
            </label>
          </div>
          <div style={{ marginLeft: '10px', marginRight: '10px' }}>
            <input
              type='radio'
              id='USD'
              name='currency'
              value='USD'
              defaultChecked={currency === 'USD'}
            />
            <label htmlFor='USD' className='w-form-label'>
              USD
            </label>
          </div>
        </div>
      </div>
    );
    fields.forEach((field) => {
      let header = <label className='inputheader'>{field.title}</label>;
      if (field.errorText) {
        header = (
          <Tippy content={field.errorText}>
            <label className='inputheader' style={{ color: '#ff8888' }}>
              {field.title}
            </label>
          </Tippy>
        );
      }

      formHtml.push(
        <div
          key={field.title}
          className={field.className ? field.className : 'inputdiv'}
        >
          {header}
          <div className='inputfielddiv'>
            <input
              type='number'
              maxLength={256}
              required
              data-name
              placeholder='0'
              className='inputfield w-input'
              value={field.value ? field.value : ''}
              onChange={(event: any) => {
                field.method(parseFloat(event.target.value) || 0);
              }}
            />
            <Tippy content={field.toolTipContent}>
              <div className='html-embed w-embed'>
                <i className='material-icons' style={{ fontSize: '18px' }}>
                  info_outline
                </i>
              </div>
            </Tippy>
          </div>
        </div>
      );
    });

    const franchiseOptionRadioGroupHtml: any[] = [];
    Object.keys(franchiseOptions).forEach((key) => {
      const fOption = franchiseOptions[key];
      let content = fOption.toolTipContent;

      const priceText = toCurrencyString(
        fOption.price * (currency === 'USD' ? 1 / inrToUsd : 1),
        currency
      );

      content = content.concat(
        <div key={uniqueKey++}>
          {`Total Vessel Capacity = ${fOption.vesselCapacity} Litres`}
          <br />
          {`${fOption.minimumServings} to ${fOption.maximumServings} Servings`}
          <br />
          {`${fOption.unitsPerHour} Electricity Units consumed per hour`}
          <br />
          {priceText}
          <br />
        </div>
      );
      if (key === franchiseOption) {
        franchiseOptionRadioGroupHtml.push(
          <Tippy key={uniqueKey++} content={content}>
            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
              <input
                type='radio'
                id={key}
                name='model'
                value={key}
                defaultChecked
              />
              <label htmlFor={key} className='w-form-label'>
                {`${key} - ${priceText}`}
              </label>
            </div>
          </Tippy>
        );
      } else {
        franchiseOptionRadioGroupHtml.push(
          <Tippy key={uniqueKey++} content={content}>
            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
              <input type='radio' id={key} name='model' value={key} />
              <label htmlFor={key} className='w-form-label'>
                {key}
              </label>
            </div>
          </Tippy>
        );
      }
    });

    formHtml.push(
      <div key={uniqueKey++} className='robochefmodelinputdiv'>
        <div className='inputheaderdiv'>
          <label className='inputheader'>Franchise Option</label>
        </div>
        <div
          className='modelsradiogroupdiv'
          onChange={(event: any) => {
            setFranchiseOption(event.target.value);
          }}
        >
          {franchiseOptionRadioGroupHtml}
        </div>
      </div>
    );

    const customFields = [
      {
        title: 'Franchise Cost',
        value: franchiseCost,
        method: setFranchiseCost,
      },
      {
        title: 'Units per hour',
        value: unitsPerHour,
        method: setUnitsPerHour,
      },
    ];

    customFields.forEach((field) => {
      formHtml.push(
        <div key={field.title} className={'inputdiv'}>
          <label className='inputheader'>{field.title}</label>
          <div className='inputfielddiv'>
            <input
              type='number'
              maxLength={256}
              required
              data-name
              placeholder='0'
              className='inputfield w-input'
              value={field.value ? field.value : ''}
              onChange={(event: any) => {
                field.method(parseFloat(event.target.value) || 0);
              }}
            />
          </div>
        </div>
      );
    });

    return formHtml;
  }

  function getResultsHTML() {
    const electricityUnitsConsumedPerMonth =
      electricityUnitsConsumedPerDay * DEFAULT_DAYS_IN_MONTH;
    const electricityCostPerMonth =
      electricityCostPerDay * DEFAULT_DAYS_IN_MONTH;
    const results = [
      {
        key: 'Electricity Units Consumed per month',
        value: electricityUnitsConsumedPerMonth.toLocaleString(
          currency === 'USD' ? 'en-US' : 'en-IN'
        ),
        toolTipContent: `Units Consumed Per Hour * Kitchen Operating Hours per day * ${DEFAULT_DAYS_IN_MONTH}`,
      },
      {
        key: 'Electricity Cost per month',
        value: toCurrencyString(electricityCostPerMonth, currency),
        toolTipContent: `Electricity Units Consumed per month * Electricity Unit Cost per hour = ${electricityUnitsConsumedPerMonth} * ${toCurrencyString(
          electricityUnitCostPerHour,
          currency
        )}`,
      },
      {
        key: 'Labor Costs per month',
        value: toCurrencyString(laborCostsPerMonth, currency),
        toolTipContent: `Number of Employees * Average Employee Salary`,
      },
      {
        key: 'Miscellaneous Costs per month',
        value: toCurrencyString(miscCostsPerMonth, currency),
        toolTipContent: `${misc1} + ${misc2} + ${misc3} + ${misc4}`,
      },
      {
        key: 'Ingredients Cost per month',
        value: toCurrencyString(ingredientsCostPerMonth, currency),
        toolTipContent: `No. of Meals per day * Ingredients Cost per Meal * ${DEFAULT_DAYS_IN_MONTH}`,
      },
      {
        key: 'Revenue per month',
        value: toCurrencyString(revenuePerMonth, currency),
        toolTipContent: `Revenue per day * ${DEFAULT_DAYS_IN_MONTH}`,
      },
      {
        key: 'Profit per month',
        value: toCurrencyString(profitPerMonth, currency),
        toolTipContent: `(Revenue - (Ingredients Cost + Electricity Cost + Labor Cost + Rent + Misc Costs)) per day * ${DEFAULT_DAYS_IN_MONTH}`,
      },
      {
        key: 'Investment',
        value: toCurrencyString(investment, currency),
        toolTipContent: `Price of Franchise Option ${franchiseOption} + Advance Deposit = ${toCurrencyString(
          franchiseCost * (currency === 'USD' ? 1 / inrToUsd : 1),
          currency
        )} + ${advanceDeposit}`,
      },
      {
        key: 'Break-Even Period (BEP)',
        value: bepString,
        toolTipContent: `Investment / Profit per day = ${toCurrencyString(
          investment,
          currency
        )} / ${toCurrencyString(
          profitPerMonth / DEFAULT_DAYS_IN_MONTH,
          currency
        )} = ${Math.ceil(
          investment / (profitPerMonth / DEFAULT_DAYS_IN_MONTH)
        )} days`,
      },
      {
        key: 'Annual ROI',
        value: `${annualRoi}%`,
        toolTipContent: 'Profit per year / Investment * 100',
      },
    ];

    const resultsHtml: any[] = [];
    results.forEach((result) => {
      resultsHtml.push(
        <Tippy key={uniqueKey++} content={result.toolTipContent}>
          <div className={'resultitemdiv'}>
            <div className='resultitemdiv__value'>{result.value}</div>
            <div className='resultitemdiv__key'>{result.key}</div>
          </div>
        </Tippy>
      );
    });

    return resultsHtml;
  }

  const changeReasonHtml = (
    <div
      style={{
        display: displayChangeReason,
        marginBottom: '10px',
        padding: '20px',
        textAlign: 'center',
        backgroundColor: '#ffdede',
      }}
    >
      <div>{changeReasonText}</div>
    </div>
  );

  const realityButton = (
    <a
      href='#request-info'
      className='resultcta w-button'
      onClick={() => {
        // html2canvas(document.body).then(function (canvas) {
        //   var imageType = 'image/png'
        //   var imageData = canvas.toDataURL(imageType)
        //   document.location.href = imageData.replace(imageType, 'image/octet-stream')
        // })
        appendToSheets();
      }}
    >
      Make this ROI your reality
    </a>
  );

  return (
    <>
      <div
        data-collapse='medium'
        data-animation='default'
        data-duration={400}
        className='sticky-nav w-nav'
      >
        <div className='navcontainer1400'>
          <div className='div-block-6'>
            <a
              href='https://www.robotickitchen.in'
              className='nav-logo-link w-inline-block'
            >
              <img
                src='images/web_hi_res_512.png'
                width={54}
                alt=''
                className='nav-logo'
              />
            </a>
            <div className='navbarlogotext'>RoboChef</div>
          </div>
          <nav role='navigation' className='nav-menu w-nav-menu'>
            <a
              href='https://www.robotickitchen.in'
              className='navlinks w-nav-link'
            >
              Home
            </a>
            <a
              href='https://www.robotickitchen.in/design'
              className='navlinks w-nav-link'
            >
              Design
            </a>
            <a
              href='https://www.robotickitchen.in/plan-your-automated-kitchen'
              className='navlinks w-nav-link'
            >
              Compare
            </a>
            <a href='#request-info' className='navbarcta w-button'>
              Request a Demo
            </a>
          </nav>
          <div className='w-nav-button'>
            <div className='w-icon-nav-menu' />
          </div>
        </div>
      </div>
      <div className='section'>
        <div className='container1400'>
          <div className='herohalfdiv'>
            <div className='div-block-4'>
              <div className='herosubtext'>ROI CALCULATOR</div>
              <div className='herosubtext2' />
            </div>
            <h1 className='heading'>
              Find out what you can save with RoboChef
            </h1>
            <div className='text-block-4'>
              This calculator determines the return on investment that you could
              realize by automating your kitchen with RoboChef’s platform.
            </div>
            <div>
              <a href='#calculator' className='herocta w-button'>
                Calculate ROI
              </a>
            </div>
          </div>
          <div className='herorighthalfdiv'>
            <div
              data-w-id='d8f49234-9f34-8cfa-eadd-fcc006466abd'
              data-animation-type='lottie'
              data-src='documents/4851-calculating.json'
              data-loop={0}
              data-direction={1}
              data-autoplay={0}
              data-is-ix2-target={1}
              data-renderer='svg'
              data-default-duration={3}
              data-duration={0}
            />
          </div>
        </div>
      </div>
      <div id='calculator' className='requestinfosection'>
        <div
          data-duration-in={600}
          data-duration-out={200}
          className='tabs w-tabs'
        >
          <div className='tabs-content w-tab-content'>
            <div data-w-tab='Simple' className='tab-pane-simple w-tab-pane'>
              <div className='container1400'>
                <img src='images/equals-update.svg' alt='' className='image' />

                <div className='calculatorleftdiv'>
                  <div className='text-block'>
                    Fill these fields based on your kitchen, to the best of your
                    knowledge
                  </div>
                  <div>
                    <form className='form'>{getFormHtml()}</form>
                    {changeReasonHtml}
                  </div>
                </div>

                <div className='calculatorrightdiv'>
                  <div className='resulttitle'>ROI Calculator Results</div>
                  <div className='resultsdiv'>{getResultsHTML()}</div>

                  {realityButton}
                </div>
              </div>
            </div>
            <div
              data-w-tab='Advanced'
              className='tab-pane-advanced w-tab-pane w--tab-active'
            >
              <div className='container1400'>
                <img src='images/equals-update.svg' alt='' className='image' />

                <div className='calculatorleftdiv'>
                  <div className='text-block'>
                    Fill these fields based on your kitchen, to the best of your
                    knowledge
                  </div>
                  <div>
                    <form className='form'>{getFormHtml()}</form>
                    {changeReasonHtml}
                  </div>
                </div>

                <div className='calculatorrightdiv'>
                  <div className='resulttitle'>ROI Calculator Results</div>
                  <div className='resultsdiv'>{getResultsHTML()}</div>

                  {realityButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='request-info' className='requestinfosection'>
        <div className='container1400'>
          <div className='requestinfoleftdiv'>
            <div className='w-richtext'>
              <h2>Start Automating Your Kitchen</h2>
              <p>
                As the market leader in Automated Kitchens, RoboChef has
                delivered measurable results to some of the world’s most
                innovative companies, helping them shift from a reactive,
                expensive kitchen strategy to a proactive model that reduces
                effort and cost.
              </p>
              <p>We’d like to show you how.</p>
              <p>
                Share your contact information, and we’ll be in touch to begin
                discussing how we can save your business time, money, and
                customers with powerful, personalized automation.
              </p>
            </div>
          </div>
          <div className='requestinforightdiv'>
            <div>
              <form
                data-name='Email Form'
                name='email-form'
                className='form'
                onSubmit={(e) => {
                  e.preventDefault();

                  appendToSheets();

                  setDisplayContactFormLoading('block');
                  setDisplayContactFormSuccess('none');
                  setDisplayContactFormFailure('none');

                  return new Promise((resolve, reject) => {
                    request.post(
                      {
                        url:
                          'https://www.robotickitchen.in/roi-calculator/php/contact-form.php',
                        form: {
                          name,
                          email,
                          phone,
                          country: placesAutocomplete.getVal(),
                          message,
                        },
                      },
                      function (error, _response, body) {
                        if (!error) {
                          resolve(body);
                        } else {
                          reject(error);
                        }
                      }
                    );
                  })
                    .catch(() => {
                      setDisplayContactFormLoading('none');
                      setDisplayContactFormSuccess('none');
                      setDisplayContactFormFailure('block');
                    })
                    .then((body: any) => {
                      setDisplayContactFormLoading('none');
                      if (body && body.indexOf('OK') !== -1) {
                        setDisplayContactFormSuccess('block');
                        setDisplayContactFormFailure('none');
                      } else {
                        setDisplayContactFormSuccess('none');
                        setDisplayContactFormFailure('block');
                      }
                    });
                }}
              >
                <div className='inputdiv'>
                  <label className='inputheader'>Name*</label>
                  <input
                    type='text'
                    maxLength={256}
                    required
                    data-name
                    className='inputfield w-input'
                    onChange={(event: any) => {
                      setName(event.target.value);
                    }}
                  />
                </div>
                <div className='inputdiv'>
                  <label className='inputheader'>Email*</label>
                  <input
                    type='email'
                    maxLength={256}
                    required
                    data-name
                    className='inputfield w-input'
                    onChange={(event: any) => {
                      setEmail(event.target.value);
                    }}
                  />
                </div>
                <div className='inputdiv'>
                  <label className='inputheader'>Phone*</label>
                  <input
                    type='phone'
                    maxLength={256}
                    required
                    data-name
                    className='inputfield w-input'
                    onChange={(event: any) => {
                      setPhone(event.target.value);
                    }}
                  />
                </div>
                <div className='inputdiv'>
                  <label className='inputheader'>Country</label>
                  <div className='f16'>
                    <input
                      type='search'
                      id='country'
                      className='inputfield w-input form-control'
                    />
                  </div>
                </div>
                <div className='inputdiv100'>
                  <label className='inputheader'>Anything else?</label>
                  <textarea
                    placeholder='Tell us more about your kitchen, needs and timeline.'
                    maxLength={5000}
                    data-name
                    className='textarea w-input'
                    onChange={(event: any) => {
                      setMessage(event.target.value);
                    }}
                  />
                </div>

                <input
                  type='submit'
                  defaultValue='Request Information'
                  data-wait
                  className='requestinfocta w-button'
                />
                <div className='text-block-3'>
                  To speak directly with us, you can also call{' '}
                  <a href='tel:+919940432545'>+91 99404 32545</a>
                </div>
              </form>
              <div
                style={{
                  display: displayContactFormLoading,
                  marginTop: '10px',
                  padding: '20px',
                  textAlign: 'center',
                  backgroundColor: '#dedede',
                }}
              >
                <div>Submitting your request. Please wait...</div>
              </div>
              <div
                style={{
                  display: displayContactFormSuccess,
                  marginTop: '10px',
                  padding: '20px',
                  textAlign: 'center',
                  backgroundColor: '#deffde',
                }}
              >
                <div>Thank you! Your request has been received!</div>
              </div>
              <div
                style={{
                  display: displayContactFormFailure,
                  marginTop: '10px',
                  padding: '20px',
                  textAlign: 'center',
                  backgroundColor: '#ffdede',
                }}
              >
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        data-collapse='medium'
        data-animation='default'
        data-duration={400}
        className='foorternav w-nav'
      >
        <div className='footercontainer'>
          <a
            href='https://www.robotickitchen.in'
            className='nav-logo-link w-inline-block'
          >
            <img
              src='images/web_hi_res_512.png'
              width={54}
              alt=''
              className='nav-logo'
            />
          </a>
          <div className='footerlogotext'>
            RoboChef © 2020 All Rights Reserved
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default Franchise;
