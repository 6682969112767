import React from 'react'

export const allModels: any = {
  AX360: {
    vesselCapacity: 3.7,
    price: 600000,
    unitsPerHour: 1,
    minimumServings: 1,
    maximumServings: 10,
    noOfVessels: 2,
    toolTipContent: [(
      <>
      AX360 - À la carte Series<br />
      24x7 Kitchens<br />
      On-demand cooking<br />
        <br />
      </>
    )]
  },
  QX900: {
    vesselCapacity: 9,
    price: 900000,
    unitsPerHour: 2,
    minimumServings: 1,
    maximumServings: 35,
    noOfVessels: 2,
    toolTipContent: [(
      <>
        QX900 - QSR Series<br />
        Quick Service Restaurants<br />
        On-demand and Bulk cooking<br />
        <br />
      </>
    )]
  },
  'QX900 Plus': {
    vesselCapacity: 9,
    price: 1500000,
    unitsPerHour: 3,
    minimumServings: 1,
    maximumServings: 35,
    noOfVessels: 4,
    toolTipContent: [(
      <>
        QX900 Plus - QSR Series<br />
        Quick Service Restaurants<br />
        On-demand and Bulk cooking<br />
        <br />
      </>
    )]
  },
  CX3700: {
    vesselCapacity: 37,
    price: 2500000,
    unitsPerHour: 4,
    minimumServings: 10,
    maximumServings: 400,
    noOfVessels: 2,
    toolTipContent: [(
      <>
        CX3700 - Commercial Series<br />
        Catering units, Central Kitchens<br />
        Bulk cooking<br />
        <br />
      </>
    )]
  },
  'CX3700 Plus': {
    vesselCapacity: 37,
    price: 3000000,
    unitsPerHour: 8,
    minimumServings: 10,
    maximumServings: 400,
    noOfVessels: 4,
    toolTipContent: [(
      <>
        CX3700 Plus - Commercial Series<br />
        Catering units, Central Kitchens<br />
        Bulk cooking<br />
        <br />
      </>
    )]
  },
  CX5000: {
    vesselCapacity: 50,
    price: 3000000,
    unitsPerHour: 8,
    minimumServings: 15,
    maximumServings: 600,
    noOfVessels: 2,
    toolTipContent: [(
      <>
        CX5000 - Commercial Series<br />
        Catering units, Central Kitchens<br />
        Bulk cooking<br />
        <br />
      </>
    )]
  },
  CX7200: {
    vesselCapacity: 72,
    price: 4500000,
    unitsPerHour: 8,
    minimumServings: 20,
    maximumServings: 800,
    noOfVessels: 2,
    toolTipContent: [(
      <>
        CX7200 - Commercial Series<br />
        Catering units, Central Kitchens<br />
        Bulk cooking<br />
        <br />
      </>
    )]
  }
}
