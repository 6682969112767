import React from 'react';

export const franchiseOptions: any = {
  A: {
    vesselCapacity: 7.2,
    price: 900000,
    unitsPerHour: 2,
    minimumServings: 1,
    maximumServings: 20,
    noOfVessels: 4,
    toolTipContent: [
      <>
        Two AX360 - À la carte Series
        <br />
        24x7 Kitchens
        <br />
        On-demand cooking
        <br />
        <br />
      </>,
    ],
  },
  B: {
    vesselCapacity: 18,
    price: 1500000,
    unitsPerHour: 4,
    minimumServings: 1,
    maximumServings: 70,
    noOfVessels: 4,
    toolTipContent: [
      <>
        Two QX900 - QSR Series
        <br />
        Quick Service Restaurants
        <br />
        On-demand and Bulk cooking
        <br />
        <br />
      </>,
    ],
  },
  C: {
    vesselCapacity: 25.2,
    price: 2100000,
    unitsPerHour: 6,
    minimumServings: 1,
    maximumServings: 90,
    noOfVessels: 8,
    toolTipContent: [
      <>
        Two QX900 + Two AX360
        <br />
        Quick Service Restaurants
        <br />
        On-demand and Bulk cooking
        <br />
        <br />
      </>,
    ],
  },
  D: {
    vesselCapacity: 74,
    price: 2900000,
    unitsPerHour: 8,
    minimumServings: 10,
    maximumServings: 800,
    noOfVessels: 4,
    toolTipContent: [
      <>
        Two CX3700 - Commercial Series
        <br />
        Catering units, Central Kitchens
        <br />
        Bulk cooking
        <br />
        <br />
      </>,
    ],
  },
  E: {
    vesselCapacity: 81.2,
    price: 3600000,
    unitsPerHour: 10,
    minimumServings: 1,
    maximumServings: 820,
    noOfVessels: 8,
    toolTipContent: [
      <>
        Two CX3700 + Two AX360
        <br />
        Catering units, Central Kitchens
        <br />
        On-demand and Bulk cooking
        <br />
        <br />
      </>,
    ],
  },
};
