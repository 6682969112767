export const DEFAULT_DAYS_IN_MONTH = 30;

export const DEFAULT_AVERAGE_RECIPE_COOKING_TIME = 60;
export const DEFAULT_KITCHEN_OPERATING_HOURS_PER_DAY = 24;

export const DEFAULT_MACHINE_COUNT = 1;

export const DEFAULT_LPG_COST_PER_KG = 73;
export const DEFAULT_ELECTRICITY_UNIT_COST_PER_HOUR = 6.5;

export const DEFAULT_NO_OF_EMPLOYEES = 1;
export const DEFAULT_AVERAGE_EMPLOYEE_SALARY = 15000;

export const DEFAULT_ADVANCE_DEPOSIT = 200000;
export const DEFAULT_MONTHLY_RENT = 20000;

export const DEFAULT_MISC_COSTS = 0;

export const DEFAULT_FRANCHISE_OPTION = 'C';
