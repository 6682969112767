import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

import request from 'request'

import Tippy from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

import { DEFAULT_AVERAGE_RECIPE_COOKING_TIME, DEFAULT_KITCHEN_OPERATING_HOURS_PER_DAY, DEFAULT_MACHINE_COUNT, DEFAULT_LPG_COST_PER_KG, DEFAULT_ELECTRICITY_UNIT_COST_PER_HOUR, DEFAULT_DAYS_IN_MONTH } from './data/constants'
import { allModels } from './data/models'

// import html2canvas from 'html2canvas'

const moment = require('moment')
require('moment-precise-range-plugin')

const places = require('places.js')

const Bearer = require('@bearer/node')('FUFbE-aEBIbyWN5aVuX3wpWVp5pMOL8C')
const gsheet = Bearer.integration('google_sheets')

const spreadsheetId = '1IfBsQbr_gcANt48qUVLLNXK8sMZnDo_O3sxuYzMduac'

const aLaCarteModels = { AX360: allModels.AX360, QX900: allModels.QX900, 'QX900 Plus': allModels['QX900 Plus'] }
const bulkCookingModels = { QX900: allModels.QX900, 'QX900 Plus': allModels['QX900 Plus'], CX3700: allModels.CX3700, 'CX3700 Plus': allModels['CX3700 Plus'], CX5000: allModels.CX5000, CX7200: allModels.CX7200 }

const inrToUsd = 75

function toCurrencyString (amount: number | undefined, currency: string) {
  let locale = 'en-IN'
  if (currency === 'USD') { locale = 'en-US' }
  if (!amount) { amount = 0 }
  return amount.toLocaleString(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    style: 'currency',
    currency: currency
  })
}

function getHumanReadableStringFromDays (days: number) {
  return moment.preciseDiff(new Date().setDate(new Date().getDate() + days), new Date())
}

let placesAutocomplete: any

let uniqueKey = 0

function Advanced () {
  const query = new URLSearchParams(useLocation().search)
  const history = useHistory()

  const [isAdvancedMode, setIsAdvancedMode] = useState((query.get('isAdvancedMode') === 'true'))

  let tmpCurrency = query.get('currency')
  if (!tmpCurrency) { tmpCurrency = 'INR' }
  const [currency, setCurrency] = useState(tmpCurrency)

  const [isAlaCarte, setIsAlaCarte] = useState((query.get('isAlaCarte') === 'true'))

  const [mealsPerDay, setMealsPerDay] = useState<number | undefined>(getValueFromQueryParams('mealsPerDay', undefined))
  const [mealsPerDayError, setMealsPerDayError] = useState('')
  const [mealSellingPrice, setMealSellingPrice] = useState<number | undefined>(getValueFromQueryParams('mealSellingPrice', undefined))
  const [mealSellingPriceError, setMealSellingPriceError] = useState('')
  const [mealIngredientsCost, setMealIngredientsCost] = useState<number | undefined>(getValueFromQueryParams('mealIngredientsCost', undefined))
  const [mealIngredientsCostError, setMealIngredientsCostError] = useState('')

  const [bulkCookingQuantity, setBulkCookingQuantity] = useState<number | undefined>(getValueFromQueryParams('bulkCookingQuantity', allModels.QX900.maximumServings))
  const [bulkCookingQuantityError, setBulkCookingQuantityError] = useState('')
  const [averageRecipeCookingTime, setAverageRecipeCookingTime] = useState<number | undefined>(getValueFromQueryParams('averageRecipeCookingTime', DEFAULT_AVERAGE_RECIPE_COOKING_TIME))
  const [averageRecipeCookingTimeError, setAverageRecipeCookingTimeError] = useState('')
  const [kitchenOperatingHoursPerDay, setKitchenOperatingHoursPerDay] = useState<number | undefined>(getValueFromQueryParams('kitchenOperatingHoursPerDay', DEFAULT_KITCHEN_OPERATING_HOURS_PER_DAY))
  const [kitchenOperatingHoursPerDayError, setKitchenOperatingHoursPerDayError] = useState('')

  let tmpRoboChefModel = query.get('robochefModel')
  if (!tmpRoboChefModel) { tmpRoboChefModel = 'QX900' }
  const [robochefModel, setRoboChefModel] = useState<string>(tmpRoboChefModel)
  const [machineCount, setMachineCount] = useState<number | undefined>(getValueFromQueryParams('machineCount', DEFAULT_MACHINE_COUNT))

  const [lpgCostPerKg, setLpgCostPerKg] = useState<number | undefined>(getValueFromQueryParams('lpgCostPerKg', DEFAULT_LPG_COST_PER_KG))
  const [lpgCostPerKgError, setLpgCostPerKgError] = useState('')
  const [electricityUnitCostPerHour, setElectricityUnitCostPerHour] = useState<number | undefined>(getValueFromQueryParams('electricityUnitCostPerHour', DEFAULT_ELECTRICITY_UNIT_COST_PER_HOUR))
  const [electricityUnitCostPerHourError, setElectricityUnitCostPerHourError] = useState('')

  const [lpgCostPerMonth, setLpgCostPerMonth] = useState(0)
  const [lpgCostSavingsPerMonth, setLpgCostSavingsPerMonth] = useState(0)

  const [electricityUnitsConsumedPerDay, setElectricityUnitsConsumedPerDay] = useState(0)
  const [electricityCostPerDay, setElectricityCostPerDay] = useState(0)

  const [profitPerMonth, setProfitPerMonth] = useState(0)

  const [investment, setInvestment] = useState(0)
  const [bepString, setBepString] = useState('-')
  const [annualRoi, setAnnualRoi] = useState(0)

  const [changeReasonText, setChangeReasonText] = useState('')
  const [displayChangeReason, setDisplayChangeReason] = useState('none')

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [message, setMessage] = useState('')

  const [displayContactFormLoading, setDisplayContactFormLoading] = useState('none')
  const [displayContactFormSuccess, setDisplayContactFormSuccess] = useState('none')
  const [displayContactFormFailure, setDisplayContactFormFailure] = useState('none')

  function getValueFromQueryParams (param: string, defaultValue: number | undefined) {
    let value
    const valueString = query.get(param)
    if (valueString) {
      value = parseFloat(valueString)
      if (value === -1) {
        value = defaultValue
      }
    } else {
      value = defaultValue
    }
    return value
  }

  function undefinedNumberToString (value: number | undefined) {
    if (!value) { value = -1 }
    return value.toString()
  }

  function getUrlQueryString () {
    const params = new URLSearchParams({
      isAdvancedMode: isAdvancedMode.toString(),
      currency: currency,
      isAlaCarte: isAlaCarte.toString(),
      mealsPerDay: undefinedNumberToString(mealsPerDay),
      mealSellingPrice: undefinedNumberToString(mealSellingPrice),
      mealIngredientsCost: undefinedNumberToString(mealIngredientsCost),
      bulkCookingQuantity: undefinedNumberToString(bulkCookingQuantity),
      averageRecipeCookingTime: undefinedNumberToString(averageRecipeCookingTime),
      kitchenOperatingHoursPerDay: undefinedNumberToString(kitchenOperatingHoursPerDay),
      robochefModel: robochefModel,
      machineCount: undefinedNumberToString(machineCount),
      lpgCostPerKg: undefinedNumberToString(lpgCostPerKg),
      electricityUnitCostPerHour: undefinedNumberToString(electricityUnitCostPerHour)
    })
    return params.toString()
  }

  useEffect(() => {
    function getUrlQueryString () {
      const params = new URLSearchParams({
        isAdvancedMode: isAdvancedMode.toString(),
        currency: currency,
        isAlaCarte: isAlaCarte.toString(),
        mealsPerDay: undefinedNumberToString(mealsPerDay),
        mealSellingPrice: undefinedNumberToString(mealSellingPrice),
        mealIngredientsCost: undefinedNumberToString(mealIngredientsCost),
        bulkCookingQuantity: undefinedNumberToString(bulkCookingQuantity),
        averageRecipeCookingTime: undefinedNumberToString(averageRecipeCookingTime),
        kitchenOperatingHoursPerDay: undefinedNumberToString(kitchenOperatingHoursPerDay),
        robochefModel: robochefModel,
        machineCount: undefinedNumberToString(machineCount),
        lpgCostPerKg: undefinedNumberToString(lpgCostPerKg),
        electricityUnitCostPerHour: undefinedNumberToString(electricityUnitCostPerHour)
      })
      return params.toString()
    }

    history.push(window.location.pathname + '?' + getUrlQueryString())
  }, [averageRecipeCookingTime, bulkCookingQuantity, currency, electricityUnitCostPerHour, history, isAdvancedMode, isAlaCarte, kitchenOperatingHoursPerDay, lpgCostPerKg, machineCount, mealIngredientsCost, mealSellingPrice, mealsPerDay, robochefModel])

  function getSheetsData () {
    return [[
      new Date(), `/?${getUrlQueryString()}`, isAdvancedMode, currency, isAlaCarte,
      mealsPerDay, mealSellingPrice, mealIngredientsCost,
      bulkCookingQuantity, averageRecipeCookingTime, kitchenOperatingHoursPerDay,
      robochefModel, machineCount,
      electricityUnitCostPerHour, electricityUnitsConsumedPerDay, electricityCostPerDay,
      lpgCostPerKg, lpgCostPerMonth, lpgCostSavingsPerMonth,
      profitPerMonth, investment, bepString, annualRoi,
      name, email, phone, placesAutocomplete.getVal(), message
    ]]
  }

  function appendToSheets () {
    const data = getSheetsData()
    gsheet.auth('23a03860-7c1d-11ea-acf0-6992bb43099f')
      .post(`${spreadsheetId}/values/A1:append`, {
        body: { values: data },
        query: { valueInputOption: 'RAW' }
      }).then(() => { console.log('Saved!') })
  }

  useEffect(() => {
    placesAutocomplete = places({
      appId: process.env.REACT_APP_ALGOLIA_APP_ID,
      apiKey: process.env.REACT_APP_ALGOLIA_SEARCH_ONLY_API_KEY,
      container: document.querySelector('#country'),
      templates: {
        suggestion: function (suggestion: any) {
          return '<i class="flag ' + suggestion.countryCode + '"></i> ' + suggestion.highlight.name
        }
      }
    }).configure({
      type: 'country'
    })
  }, [])

  useEffect(() => {
    function showChangeReason (text: string) {
      console.log(text)
      setChangeReasonText(text)
      if (displayChangeReason !== 'block') {
        setDisplayChangeReason('block')
      }
    }

    function calculateRoi () {
      const models: any = (isAlaCarte) ? aLaCarteModels : bulkCookingModels
      let model = models[robochefModel]
      if (!model) {
        setRoboChefModel('QX900')
        return
      }

      // Simple Mode
      if (!isAdvancedMode) {
        if (averageRecipeCookingTime !== DEFAULT_AVERAGE_RECIPE_COOKING_TIME) {
          setAverageRecipeCookingTime(DEFAULT_AVERAGE_RECIPE_COOKING_TIME)
        }
        if (kitchenOperatingHoursPerDay !== DEFAULT_KITCHEN_OPERATING_HOURS_PER_DAY) {
          setKitchenOperatingHoursPerDay(DEFAULT_KITCHEN_OPERATING_HOURS_PER_DAY)
        }
      }

      // Kitchen Operating Hours per day
      if (kitchenOperatingHoursPerDay && kitchenOperatingHoursPerDay <= 0) {
        setKitchenOperatingHoursPerDayError('Kitchen Operating Hours per day must be atleast 1 hour')
      }
      if (kitchenOperatingHoursPerDay && kitchenOperatingHoursPerDay > 24) {
        setKitchenOperatingHoursPerDayError('Kitchen Operating Hours per day cannot be more than 24 hours')
      }
      if (kitchenOperatingHoursPerDay && kitchenOperatingHoursPerDay >= 1 && kitchenOperatingHoursPerDay <= 24) {
        setKitchenOperatingHoursPerDayError('')
      }

      // Average Recipe Cooking Time in minutes
      if (averageRecipeCookingTime && averageRecipeCookingTime <= 0) {
        setAverageRecipeCookingTimeError('Average Recipe Cooking Time must be atleast 1 minute')
      }
      if (averageRecipeCookingTime && averageRecipeCookingTime >= 1) {
        setAverageRecipeCookingTimeError('')
      }
      if (averageRecipeCookingTime && kitchenOperatingHoursPerDay && averageRecipeCookingTime > (kitchenOperatingHoursPerDay * 60)) {
        setAverageRecipeCookingTimeError(`Average Recipe Cooking Time in minutes cannot be more than Kitchen Operating Minutes per day which is ${kitchenOperatingHoursPerDay * 60}`)
      }
      if (averageRecipeCookingTime && kitchenOperatingHoursPerDay && averageRecipeCookingTime <= (kitchenOperatingHoursPerDay * 60)) {
        setAverageRecipeCookingTimeError('')
      }

      // No. of Meals per day
      if (mealsPerDay && mealsPerDay <= 0) {
        setMealsPerDayError('No. of meals per day must be atleast 1 meal')
      }
      if (mealsPerDay && mealsPerDay >= 1) {
        setMealsPerDayError('')
      }

      // Selling Price of a meal
      if (mealSellingPrice && mealSellingPrice < 0) {
        setMealSellingPriceError('Selling price of a meal cannot be less than 0')
      }
      if (mealSellingPrice && mealSellingPrice >= 0) {
        setMealSellingPriceError('')
      }

      // Ingredients Cost per meal
      if (mealIngredientsCost && mealIngredientsCost < 0) {
        setMealIngredientsCostError('Ingredients Cost cannot be less than 0')
      }
      if (mealIngredientsCost && mealIngredientsCost >= 0) {
        setMealIngredientsCostError('')
      }

      // No. of Machines
      if (!machineCount) {
        setMachineCount(1)
        return
      }
      if (machineCount && machineCount <= 0) {
        setMachineCount(1)
        return
      }

      if (!bulkCookingQuantity || bulkCookingQuantity < 0) { return }

      // Decrease RoboChef Model due to minimum servings
      if (bulkCookingQuantity < model.minimumServings) {
        for (let i = Object.keys(models).length - 1; i >= 0; i--) {
          const key = Object.keys(models)[i]
          const m = models[key]
          if (m.minimumServings <= bulkCookingQuantity) {
            showChangeReason(`Changed model from ${robochefModel} to ${key} since ${robochefModel} has a minimum cooking limit of ${model.minimumServings} per vessel`)
            setRoboChefModel(key)
            model = m
            break
          }
        }
      }

      if (!machineCount || !kitchenOperatingHoursPerDay || !averageRecipeCookingTime || !mealsPerDay) { return }

      // Increase Machine count due to meals per day
      let recipesPerDay = Math.floor(60 * kitchenOperatingHoursPerDay / averageRecipeCookingTime) * machineCount * model.noOfVessels
      if (recipesPerDay === 0) { return }
      let cookingQuantity = model.maximumServings
      if (bulkCookingQuantity < cookingQuantity) {
        cookingQuantity = bulkCookingQuantity
        setBulkCookingQuantityError(`Bulk Cooking Quantity can be increased to ${model.maximumServings} which is ${robochefModel}'s maximum servings in a vessel`)
      }

      let maxMealsPerDay = cookingQuantity * recipesPerDay
      const oldMachineCount = machineCount
      let newMachineCount = machineCount
      while (maxMealsPerDay < mealsPerDay) {
        newMachineCount = newMachineCount + 1
        recipesPerDay = Math.floor(60 * kitchenOperatingHoursPerDay / averageRecipeCookingTime) * newMachineCount * model.noOfVessels
        maxMealsPerDay = cookingQuantity * recipesPerDay
      }
      if (oldMachineCount !== newMachineCount) {
        setMachineCount(newMachineCount)
        showChangeReason(`Increased machine count from ${oldMachineCount} to ${newMachineCount} to make meals per day possible`)
        return
      }
      // Decrease Machine count due to meals per day
      recipesPerDay = Math.floor(60 * kitchenOperatingHoursPerDay / averageRecipeCookingTime) * (machineCount - 1) * model.noOfVessels
      newMachineCount = machineCount - 1
      maxMealsPerDay = cookingQuantity * recipesPerDay
      while (recipesPerDay && mealsPerDay <= maxMealsPerDay) {
        newMachineCount = newMachineCount - 1
        recipesPerDay = Math.floor(60 * kitchenOperatingHoursPerDay / averageRecipeCookingTime) * newMachineCount * model.noOfVessels
        maxMealsPerDay = cookingQuantity * recipesPerDay
      }
      newMachineCount = newMachineCount + 1
      if (oldMachineCount !== newMachineCount && bulkCookingQuantity <= (model.maximumServings * model.noOfVessels * newMachineCount)) {
        setMachineCount(newMachineCount)
        showChangeReason(`Decreased machine count from ${oldMachineCount} to ${newMachineCount} which is the minimum required for the given meals per day`)
        return
      }

      let currencyConversionFactor = 1
      if (currency === 'USD') { currencyConversionFactor = 1 / inrToUsd }

      if (!mealSellingPrice) { return }
      if (!mealIngredientsCost) { return }

      const revenue = mealsPerDay * mealSellingPrice
      const totalIngredientsCost = mealsPerDay * mealIngredientsCost

      const profitPerDay = revenue - totalIngredientsCost - electricityCostPerDay
      setProfitPerMonth(profitPerDay * 30)

      setInvestment(model.price * machineCount * currencyConversionFactor)
      const bepInDays = Math.ceil(investment / profitPerDay)
      if (bepInDays < 0) {
        setBepString('-')
      } else {
        setBepString(getHumanReadableStringFromDays(bepInDays))
      }
      const profitPerYear = profitPerDay * 365
      setAnnualRoi(Math.round(profitPerYear / investment * 100))
    }
    calculateRoi()
  }, [averageRecipeCookingTime, bulkCookingQuantity, currency, displayChangeReason, electricityCostPerDay, investment, isAdvancedMode, isAlaCarte, kitchenOperatingHoursPerDay, machineCount, mealIngredientsCost, mealSellingPrice, mealsPerDay, robochefModel])

  useEffect(() => {
    const models: any = (isAlaCarte) ? aLaCarteModels : bulkCookingModels
    const model = models[robochefModel]

    if (!isAdvancedMode) {
      if (bulkCookingQuantity !== model.maximumServings) {
        setBulkCookingQuantity(model.maximumServings)
        return
      }
    }

    if (bulkCookingQuantity && bulkCookingQuantity < models[Object.keys(models)[0]].minimumServings) {
      setBulkCookingQuantityError(`Bulk Cooking Quantity must be atleast ${models[Object.keys(models)[0]].minimumServings} serving`)
    }
    if (bulkCookingQuantity && bulkCookingQuantity >= models[Object.keys(models)[0]].minimumServings) {
      setBulkCookingQuantityError('')
    }
  }, [bulkCookingQuantity, isAdvancedMode, isAlaCarte, robochefModel])

  useEffect(() => {
    function showChangeReason (text: string) {
      console.log(text)
      setChangeReasonText(text)
      if (displayChangeReason !== 'block') {
        setDisplayChangeReason('block')
      }
    }

    const models: any = (isAlaCarte) ? aLaCarteModels : bulkCookingModels
    const model = models[robochefModel]

    if (!bulkCookingQuantity || bulkCookingQuantity < 0) { return }

    // Downgrade RoboChef Model due to maximum servings
    if (bulkCookingQuantity < model.maximumServings) {
      for (let i = 0; i < Object.keys(models).length; i++) {
        const key = Object.keys(models)[i]
        const m = models[key]

        if (bulkCookingQuantity <= m.maximumServings) {
          if (robochefModel !== key) {
            showChangeReason(`Changed model from ${robochefModel} to ${key} since ${key} is enough for the required bulk cooking quantity`)
            setRoboChefModel(key)
          }
          break
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkCookingQuantity, isAlaCarte])

  useEffect(() => {
    if (lpgCostPerKg && lpgCostPerKg < 0) {
      setLpgCostPerKgError('LPG Cost per kg cannot be less than 0')
    }
    if (lpgCostPerKg && lpgCostPerKg >= 0) {
      setLpgCostPerKgError('')
    }

    if (!lpgCostPerKg) { return }

    // Equivalent Gas Cost per month
    const lpgKgToLiterFactor = 1.96
    const lpgLiterToMjFactor = 25
    const mJToKwHrFactor = 0.2778

    const oneKgLpgInLiters = 1 * lpgKgToLiterFactor
    const oneKgLpgInMJ = oneKgLpgInLiters * lpgLiterToMjFactor
    const oneKgLpgMjToKwHr = oneKgLpgInMJ * mJToKwHrFactor
    const lpgCostPerKwHr = lpgCostPerKg / oneKgLpgMjToKwHr

    const inductionToGasEfficiencyFactor = 0.4
    const electricityUnitsConsumedPerMonth = electricityUnitsConsumedPerDay * DEFAULT_DAYS_IN_MONTH
    const equivalentGasUnitConsumedPerMonth = electricityUnitsConsumedPerMonth + (electricityUnitsConsumedPerMonth * inductionToGasEfficiencyFactor)
    const tmpLpgCostPerMonth = equivalentGasUnitConsumedPerMonth * lpgCostPerKwHr
    setLpgCostPerMonth(tmpLpgCostPerMonth)
  }, [displayChangeReason, electricityUnitsConsumedPerDay, lpgCostPerKg])

  useEffect(() => {
    const electricityCostPerMonth = electricityCostPerDay * DEFAULT_DAYS_IN_MONTH
    setLpgCostSavingsPerMonth(lpgCostPerMonth - electricityCostPerMonth)
  }, [electricityCostPerDay, lpgCostPerMonth])

  useEffect(() => {
    if (!isAdvancedMode) {
      if (electricityUnitCostPerHour !== DEFAULT_ELECTRICITY_UNIT_COST_PER_HOUR) {
        setElectricityUnitCostPerHour(DEFAULT_ELECTRICITY_UNIT_COST_PER_HOUR)
        return
      }
    }

    if (electricityUnitCostPerHour && electricityUnitCostPerHour < 0) {
      setElectricityUnitCostPerHourError('Electricity Unit Cost per hour cannot be less than 0')
    }
    if (electricityUnitCostPerHour && electricityUnitCostPerHour >= 0) {
      setElectricityUnitCostPerHourError('')
    }
  }, [electricityUnitCostPerHour, isAdvancedMode])

  useEffect(() => {
    if (!electricityUnitCostPerHour || !machineCount || !kitchenOperatingHoursPerDay) { return }

    const model = allModels[robochefModel]
    const machineHoursPerDay = machineCount * kitchenOperatingHoursPerDay
    setElectricityUnitsConsumedPerDay(model.unitsPerHour * machineHoursPerDay)
  }, [electricityUnitCostPerHour, kitchenOperatingHoursPerDay, machineCount, robochefModel])

  useEffect(() => {
    if (!electricityUnitCostPerHour) { return }
    setElectricityCostPerDay(electricityUnitsConsumedPerDay * electricityUnitCostPerHour)
  }, [electricityUnitCostPerHour, electricityUnitsConsumedPerDay])

  function getFormHtml (isSimple: boolean) {
    const fields = [
      { title: 'Average Bulk Cooking Quantity per cycle in servings', value: bulkCookingQuantity, method: setBulkCookingQuantity, errorText: bulkCookingQuantityError, toolTipContent: 'What is the average number of servings in one vessel? Increasing this value decreases the number of machines needed.', simple: false, advanced: true },
      // { title: 'No. of Cooking Cycles per day', value: noOfCookingCyclesPerDay, method: setNoOfCookingCyclesPerDay, toolTipContent: '', simple: false, advanced: false },
      { title: 'No. of Meals per day', value: mealsPerDay, method: setMealsPerDay, errorText: mealsPerDayError, toolTipContent: 'How many meals are sold in a day? Increasing this value increases the number of machines needed', simple: true, advanced: true },
      { title: 'Average Recipe Cooking Time in minutes', value: averageRecipeCookingTime, method: setAverageRecipeCookingTime, errorText: averageRecipeCookingTimeError, toolTipContent: 'Increasing this value decreases the number of cooking cycles per day and therefore increases the number of machines needed to make the required meals per day', simple: false, advanced: true },
      { title: 'Kitchen Operating Hours per day', value: kitchenOperatingHoursPerDay, method: setKitchenOperatingHoursPerDay, errorText: kitchenOperatingHoursPerDayError, toolTipContent: 'Number of cooking cycles per day = (Kitchen Operating Hours per day * 60) / Average Recipe Cooking Time in minutes', simple: false, advanced: true },
      { title: 'Selling Price of a meal', value: mealSellingPrice, method: setMealSellingPrice, errorText: mealSellingPriceError, toolTipContent: 'Revenue per day = Selling Price of a meal * No. of Meals per day', simple: true, advanced: true },
      { title: 'Ingredients Cost per meal', value: mealIngredientsCost, method: setMealIngredientsCost, errorText: mealIngredientsCostError, toolTipContent: 'Profit per day = Revenue per day - (Ingredients Cost per meal * No. of Meals per day) - Electricity Cost per day', simple: true, advanced: true },
      { title: 'LPG Cost per kg', value: lpgCostPerKg, method: setLpgCostPerKg, errorText: lpgCostPerKgError, toolTipContent: 'Used to calculate Equivalent Gas Cost per month', simple: false, advanced: true },
      { title: 'Electricity Unit Cost per hour', value: electricityUnitCostPerHour, method: setElectricityUnitCostPerHour, errorText: electricityUnitCostPerHourError, toolTipContent: 'Used to calculate Electricity Cost per month', simple: false, advanced: true }
    ]

    const formHtml: any[] = []
    formHtml.push(
      <div key={uniqueKey++} className='robochefmodelinputdiv'>
        <div
          className='twobuttonradiogroupdiv'
          onChange={(event: any) => { setCurrency(event.target.value) }}
        >
          <div style={{ marginLeft: '10px', marginRight: '10px' }}>
            <input type='radio' id='INR' name='currency' value='INR' defaultChecked={currency === 'INR'} />
            <label htmlFor='INR' className='w-form-label'>INR</label>
          </div>
          <div style={{ marginLeft: '10px', marginRight: '10px' }}>
            <input type='radio' id='USD' name='currency' value='USD' defaultChecked={currency === 'USD'} />
            <label htmlFor='USD' className='w-form-label'>USD</label>
          </div>
        </div>
      </div>
    )
    formHtml.push(
      <div key={uniqueKey++} className='robochefmodelinputdiv'>
        <div
          className='modelsradiogroupdiv'
          onChange={(event: any) => {
            if (event.target.value === 'À la carte') {
              setIsAlaCarte(true)
            } else if (event.target.value === 'Bulk Cooking') {
              setIsAlaCarte(false)
            }
          }}
        >
          <div style={{ marginLeft: '10px', marginRight: '10px' }}>
            <input type='radio' id='Bulk Cooking' name='cookingType' value='Bulk Cooking' defaultChecked={!isAlaCarte} />
            <label htmlFor='Bulk Cooking' className='w-form-label'>Bulk Cooking</label>
          </div>
          <Tippy content={'À la carte is the practice of ordering individual dishes from a menu in a restaurant, as opposed to table d\'hôte, where a set menu is offered. It is an early 19th century French phrase meaning "according to the menu"'}>
            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
              <input type='radio' id='À la carte' name='cookingType' value='À la carte' defaultChecked={isAlaCarte} />
              <label htmlFor='À la carte' className='w-form-label'>À la carte</label>
            </div>
          </Tippy>
        </div>
      </div>
    )
    fields.forEach(field => {
      if (isSimple && !field.simple) { return }
      if (!isSimple && !field.advanced) { return }

      let header = (<label className='inputheader'>{field.title}</label>)
      if (field.errorText) {
        header = (
          <Tippy content={field.errorText}>
            <label className='inputheader' style={{ color: '#ff8888' }}>{field.title}</label>
          </Tippy>
        )
      }

      formHtml.push(
        <div key={field.title} className={isSimple ? 'inputdiv100' : 'inputdiv'}>
          {header}
          <div className='inputfielddiv'>
            <input
              type='number' maxLength={256} required data-name placeholder='0' className='inputfield w-input'
              value={field.value ? field.value : ''}
              onChange={(event: any) => {
                field.method(event.target.value)
              }}
            />
            <Tippy content={field.toolTipContent}>
              <div className='html-embed w-embed'>
                <i className='material-icons' style={{ fontSize: '18px' }}>info_outline</i>
              </div>
            </Tippy>
          </div>
        </div>
      )
    })

    const models: any = (isAlaCarte) ? aLaCarteModels : bulkCookingModels
    const modelRadioGroupHtml: any[] = []
    Object.keys(models).forEach(key => {
      const model = models[key]
      let content = model.toolTipContent
      
      let countString = ''
      switch (model.noOfVessels) {
        case 1:
          countString = 'One'
          break;
        case 2:
          countString = 'Two'
          break;
        case 3:
          countString = 'Three'
          break;
        case 4:
          countString = 'Four'
          break;
      
        default:
          countString = ''
          break;
      }
      content = content.concat((
        <div key={uniqueKey++}>
          {(model.noOfVessels === 1) ? `${countString} ${model.vesselCapacity} Litres Vessel` : `${countString} ${model.vesselCapacity} Litres Vessels`}<br />
          {`${model.minimumServings} to ${model.maximumServings} Servings in a Vessel`}<br />
          {`${model.unitsPerHour} Electricity Units consumed per hour`}<br />
          {`${toCurrencyString(model.price * ((currency === 'USD') ? 1 / 75 : 1), currency)}`}<br />
        </div>
      ))
      if (key === robochefModel) {
        modelRadioGroupHtml.push(
          <Tippy key={uniqueKey++} content={content}>
            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
              <input type='radio' id={key} name='model' value={key} defaultChecked />
              <label htmlFor={key} className='w-form-label'>{`${machineCount || ''} ${key}`}</label>
            </div>
          </Tippy>
        )
      } else {
        modelRadioGroupHtml.push(
          <Tippy key={uniqueKey++} content={content}>
            <div style={{ marginLeft: '10px', marginRight: '10px' }}>
              <input type='radio' id={key} name='model' value={key} />
              <label htmlFor={key} className='w-form-label'>{key}</label>
            </div>
          </Tippy>
        )
      }
    })

    formHtml.push(
      <div key={uniqueKey++} className='robochefmodelinputdiv'>
        <div className='inputheaderdiv'>
          <label className='inputheader'>RoboChef Model</label>
        </div>
        <div
          className='modelsradiogroupdiv'
          onChange={(event: any) => {
            setRoboChefModel(event.target.value)
          }}
        >
          {modelRadioGroupHtml}
        </div>
      </div>
    )
    return formHtml
  }

  function getResultsHTML (isSimple: boolean) {
    const electricityUnitsConsumedPerMonth = electricityUnitsConsumedPerDay * DEFAULT_DAYS_IN_MONTH
    const electricityCostPerMonth = electricityCostPerDay * DEFAULT_DAYS_IN_MONTH
    const results = [
      { key: 'Electricity Units Consumed per month', value: electricityUnitsConsumedPerMonth.toLocaleString('en-IN'), toolTipContent: 'Units Consumed Per Hour for selected RoboChef Model * Machine Count * Kitchen Operating Hours per day * 30', simple: false, advanced: true },
      { key: 'Electricity Cost per month', value: toCurrencyString(electricityCostPerMonth, currency), toolTipContent: `Electricity Units Consumed per month * Electricity Unit Cost per hour = ${electricityUnitsConsumedPerMonth} * ${toCurrencyString(electricityUnitCostPerHour, currency)}`, simple: true, advanced: true },
      { key: 'Equivalent Gas Cost per month', value: toCurrencyString(lpgCostPerMonth, currency), toolTipContent: 'Equivalent Gas Units Consumed per month * LPG Cost Per kWHr', simple: false, advanced: true },
      { key: 'Gas Cost Savings per month', value: toCurrencyString(lpgCostSavingsPerMonth, currency), toolTipContent: `Equivalent Gas Cost per month - Electricity Cost per month = ${toCurrencyString(lpgCostPerMonth, currency)} - ${toCurrencyString(electricityCostPerMonth, currency)}`, simple: false, advanced: true },
      { key: 'Profit per month', value: toCurrencyString(profitPerMonth, currency), toolTipContent: 'Revenue - Ingredients Cost - Electricity Cost per day * 30', simple: true, advanced: true },
      { key: 'Investment', value: toCurrencyString(investment, currency), toolTipContent: `Price of ${robochefModel} * Machine Count = ${toCurrencyString(allModels[robochefModel].price * ((currency === 'USD') ? 1 / 75 : 1), currency)} * ${machineCount}`, simple: true, advanced: true },
      { key: 'Break-Even Period (BEP)', value: bepString, toolTipContent: `Investment / Profit per day = ${toCurrencyString(investment, currency)} / ${toCurrencyString(profitPerMonth / 30, currency)} = ${Math.ceil(investment / (profitPerMonth / 30))} days`, simple: true, advanced: true },
      { key: 'Annual ROI', value: `${annualRoi}%`, toolTipContent: 'Profit per year / Investment * 100', simple: true, advanced: true }
    ]

    const resultsHtml: any[] = []
    results.forEach(result => {
      if (isSimple && !result.simple) { return }
      if (!isSimple && !result.advanced) { return }

      resultsHtml.push(
        <Tippy key={uniqueKey++} content={result.toolTipContent}>
          <div className={isSimple ? 'resultitemdiv100' : 'resultitemdiv'}>
            <div className='resultitemdiv__value'>{result.value}</div>
            <div className='resultitemdiv__key'>{result.key}</div>
          </div>
        </Tippy>
      )
    })

    return resultsHtml
  }

  const changeReasonHtml = (
    <div style={{ display: displayChangeReason, marginBottom: '10px', padding: '20px', textAlign: 'center', backgroundColor: '#ffdede' }}>
      <div>{changeReasonText}</div>
    </div>
  )

  const simpleFormHtml = getFormHtml(true)
  const advancedFormHtml = getFormHtml(false)

  const realityButton = (
    <a
      href='#request-info' className='resultcta w-button'
      onClick={() => {
        // html2canvas(document.body).then(function (canvas) {
        //   var imageType = 'image/png'
        //   var imageData = canvas.toDataURL(imageType)
        //   document.location.href = imageData.replace(imageType, 'image/octet-stream')
        // })
        appendToSheets()
      }}
    >
      Make this ROI your reality
    </a>
  )

  return (
    <>
      <div data-collapse='medium' data-animation='default' data-duration={400} className='sticky-nav w-nav'>
        <div className='navcontainer1400'>
          <div className='div-block-6'>
            <a href='https://www.robotickitchen.in' className='nav-logo-link w-inline-block'>
              <img src='images/web_hi_res_512.png' width={54} alt='' className='nav-logo' />
            </a>
            <div className='navbarlogotext'>RoboChef</div>
          </div>
          <nav role='navigation' className='nav-menu w-nav-menu'>
            <a href='https://www.robotickitchen.in' className='navlinks w-nav-link'>Home</a>
            <a href='https://www.robotickitchen.in/design' className='navlinks w-nav-link'>Design</a>
            <a href='https://www.robotickitchen.in/plan-your-automated-kitchen' className='navlinks w-nav-link'>Compare</a>
            <a href='#request-info' className='navbarcta w-button'>Request a Demo</a>
          </nav>
          <div className='w-nav-button'>
            <div className='w-icon-nav-menu' />
          </div>
        </div>
      </div>
      <div className='section'>
        <div className='container1400'>
          <div className='herohalfdiv'>
            <div className='div-block-4'>
              <div className='herosubtext'>ROI CALCULATOR</div>
              <div className='herosubtext2' />
            </div>
            <h1 className='heading'>Find out what you can save with RoboChef</h1>
            <div className='text-block-4'>This calculator determines the return on investment that you could realize by automating your kitchen with RoboChef’s platform.</div>
            <div><a href='#calculator' className='herocta w-button'>Calculate ROI</a></div>
          </div>
          <div className='herorighthalfdiv'>
            <div data-w-id='d8f49234-9f34-8cfa-eadd-fcc006466abd' data-animation-type='lottie' data-src='documents/4851-calculating.json' data-loop={0} data-direction={1} data-autoplay={0} data-is-ix2-target={1} data-renderer='svg' data-default-duration={3} data-duration={0} />
          </div>
        </div>
      </div>
      <div id='calculator' className='requestinfosection'>
        <div data-duration-in={600} data-duration-out={200} className='tabs w-tabs'>
          <div className='tabs-menu w-tab-menu'>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a data-w-tab='Simple' className={`tablink w-inline-block w-tab-link${!isAdvancedMode ? ' w--current' : ''}`} onClick={() => setIsAdvancedMode(false)}>
              <div>Simple</div>
            </a>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a data-w-tab='Advanced' className={`tablink w-inline-block w-tab-link${isAdvancedMode ? ' w--current' : ''}`} onClick={() => setIsAdvancedMode(true)}>
              <div>Advanced</div>
            </a>
          </div>
          <div className='tabs-content w-tab-content'>
            <div data-w-tab='Simple' className='tab-pane-simple w-tab-pane'>
              <div className='container1400'>
                <img src='images/equals-update.svg' alt='' className='image' />

                <div className='calculatorleftdiv'>
                  <div className='text-block'>Fill these fields based on your kitchen, to the best of your knowledge</div>
                  <div>
                    <form className='form'>
                      {simpleFormHtml}
                    </form>
                    {changeReasonHtml}
                  </div>
                </div>

                <div className='calculatorrightdiv'>
                  <div className='resulttitle'>ROI Calculator Results</div>
                  <div className='resultsdiv'>
                    {getResultsHTML(true)}
                  </div>

                  {realityButton}
                </div>
              </div>
            </div>
            <div data-w-tab='Advanced' className='tab-pane-advanced w-tab-pane w--tab-active'>
              <div className='container1400'>
                <img src='images/equals-update.svg' alt='' className='image' />

                <div className='calculatorleftdiv'>
                  <div className='text-block'>Fill these fields based on your kitchen, to the best of your knowledge</div>
                  <div>
                    <form className='form'>
                      {advancedFormHtml}
                    </form>
                    {changeReasonHtml}
                  </div>
                </div>

                <div className='calculatorrightdiv'>
                  <div className='resulttitle'>ROI Calculator Results</div>
                  <div className='resultsdiv'>
                    {getResultsHTML(false)}
                  </div>

                  {realityButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id='request-info' className='requestinfosection'>
        <div className='container1400'>
          <div className='requestinfoleftdiv'>
            <div className='w-richtext'>
              <h2>Start Automating Your Kitchen</h2>
              <p>As the market leader in Automated Kitchens, RoboChef has delivered measurable results to some of the world’s most innovative companies, helping them shift from a reactive, expensive kitchen strategy to a proactive model that reduces effort and cost.</p>
              <p>We’d like to show you how.</p>
              <p>Share your contact information, and we’ll be in touch to begin discussing how we can save your business time, money, and customers with powerful, personalized automation.</p>
            </div>
          </div>
          <div className='requestinforightdiv'>
            <div>
              <form
                data-name='Email Form' name='email-form' className='form'
                onSubmit={(e) => {
                  e.preventDefault()

                  appendToSheets()

                  setDisplayContactFormLoading('block')
                  setDisplayContactFormSuccess('none')
                  setDisplayContactFormFailure('none')

                  return new Promise((resolve, reject) => {
                    request.post({
                      url: 'https://www.robotickitchen.in/roi-calculator/php/contact-form.php',
                      form: { name, email, phone, country: placesAutocomplete.getVal(), message }
                    }, function (error, _response, body) {
                      if (!error) { resolve(body) } else { reject(error) }
                    })
                  }).catch(() => {
                    setDisplayContactFormLoading('none')
                    setDisplayContactFormSuccess('none')
                    setDisplayContactFormFailure('block')
                  }).then((body: any) => {
                    setDisplayContactFormLoading('none')
                    if (body && body.indexOf('OK') !== -1) {
                      setDisplayContactFormSuccess('block')
                      setDisplayContactFormFailure('none')
                    } else {
                      setDisplayContactFormSuccess('none')
                      setDisplayContactFormFailure('block')
                    }
                  })
                }}
              >
                <div className='inputdiv'>
                  <label className='inputheader'>Name*</label>
                  <input
                    type='text' maxLength={256} required data-name className='inputfield w-input'
                    onChange={(event: any) => {
                      setName(event.target.value)
                    }}
                  />
                </div>
                <div className='inputdiv'>
                  <label className='inputheader'>Email*</label>
                  <input
                    type='email' maxLength={256} required data-name className='inputfield w-input'
                    onChange={(event: any) => {
                      setEmail(event.target.value)
                    }}
                  />
                </div>
                <div className='inputdiv'>
                  <label className='inputheader'>Phone*</label>
                  <input
                    type='phone' maxLength={256} required data-name className='inputfield w-input'
                    onChange={(event: any) => {
                      setPhone(event.target.value)
                    }}
                  />
                </div>
                <div className='inputdiv'>
                  <label className='inputheader'>Country</label>
                  <div className='f16'>
                    <input type='search' id='country' className='inputfield w-input form-control' />
                  </div>
                </div>
                <div className='inputdiv100'>
                  <label className='inputheader'>Anything else?</label>
                  <textarea
                    placeholder='Tell us more about your kitchen, needs and timeline.' maxLength={5000} data-name className='textarea w-input'
                    onChange={(event: any) => {
                      setMessage(event.target.value)
                    }}
                  />
                </div>

                <input type='submit' defaultValue='Request Information' data-wait className='requestinfocta w-button' />
                <div className='text-block-3'>To speak directly with us, you can also call <a href='tel:+919940432545'>+91 99404 32545</a></div>
              </form>
              <div style={{ display: displayContactFormLoading, marginTop: '10px', padding: '20px', textAlign: 'center', backgroundColor: '#dedede' }}>
                <div>Submitting your request. Please wait...</div>
              </div>
              <div style={{ display: displayContactFormSuccess, marginTop: '10px', padding: '20px', textAlign: 'center', backgroundColor: '#deffde' }}>
                <div>Thank you! Your request has been received!</div>
              </div>
              <div style={{ display: displayContactFormFailure, marginTop: '10px', padding: '20px', textAlign: 'center', backgroundColor: '#ffdede' }}>
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div data-collapse='medium' data-animation='default' data-duration={400} className='foorternav w-nav'>
        <div className='footercontainer'>
          <a href='https://www.robotickitchen.in' className='nav-logo-link w-inline-block'>
            <img src='images/web_hi_res_512.png' width={54} alt='' className='nav-logo' />
          </a>
          <div className='footerlogotext'>RoboChef © 2020 All Rights Reserved<br /></div>
        </div>
      </div>
    </>
  )
}

export default Advanced
